import { useNavigate } from "react-router-dom"

export default function CartElement(props){
    const navigate = useNavigate();
    return(
        <>

            <div onClick={()=>navigate("/cart")} key={"cart" + props.count} className="cart">
                <label>
                    CLICK TO OPEN CART
                </label>
                <img  src="cart.png"/>
                <div>    
                    {props.count}                
                </div>
            </div>
        </>
    )
}