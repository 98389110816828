import { isMobile } from "react-device-detect";
import Bottle from "../components/bottle";
import MainLayout from "../layout";
import "../styles/biography.scss"
import { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import * as Constants from "../config";

function Biography(){

    const wrapperRef = useRef(null);
    const placebottleStyle = {
        marginTop: isMobile ? "70px" : "20px",
    }

    const [openModal, setopenModal] = useState(false);
    const [dataState, setDataState] = useState([]);
    const [enlargeImageSource, setEnlargeImageSource] = useState("");

    function generateChildPhoto(sdata){
        for(var a in sdata){
            for(var i=1; i<10;i++){
                if(sdata[a].childphoto == undefined) sdata[a].childphoto = [];
                sdata[a].childphoto.push({photo:sdata[a]["picture"+i]});
            }
        }
        return sdata;
    }
    
    
    const [selectedCharacter, setSelectedCharacter] = useState(0);
    
    async function getData() {
        fetch(Constants.apiurl + Constants.getmembersPath)
        .then((response) => response.json())
        .then((data) => {
            var d = [];
            Object.assign(d,data)
            setDataState(generateChildPhoto(d));
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {
        function handleClickOutside(event) {
            console.log(enlargeImageSource);
          if (wrapperRef.current && 
            !wrapperRef.current.contains(event.target)
            &&
            enlargeImageSource === ""
        ) {
            setopenModal(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [wrapperRef,enlargeImageSource]);

    useEffect(() => {
        getData();
      }, []);


    const openModalClick = (item) => {
        setSelectedCharacter(item);
        setopenModal(true);
    }

    function slideLeft(){    
        console.log("sliede");    
        setSelectedCharacter(getNextIndex());        
    }

    function slideRight(){        
        console.log("sliede");   
        setSelectedCharacter(getPreviousIndex());        
    }

    function getNextIndex(){
        if(dataState.length)
            if(selectedCharacter + 1 >= dataState.length)
                return 0;
            else
                return selectedCharacter + 1;
    }

    function getPreviousIndex(){
        if(dataState.length)
            if(selectedCharacter - 1 < 0)
                return dataState.length - 1;
            else
                return selectedCharacter - 1;
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => slideLeft(),
        onSwipedRight: () => slideRight(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
      });

    const childPhoto = (photo) => {
        return(<>
            <img src={photo} onClick={()=>setEnlargeImageSource(photo)} />
        </>)
    }
    
    const enlargeImageBlock = {
        display: !!(enlargeImageSource) ? "" : "none"
    }

    const modalHtml = () => {
        return(
        <div {...handlers}>
            <div onClick={()=>setEnlargeImageSource("")} style={enlargeImageBlock} className="modal">    
                <img className="enlarge-image" src={enlargeImageSource}/>  
            </div>
            <div ref={wrapperRef} className="character-modal">
                <div>
                    <img onClick={()=>setEnlargeImageSource(dataState[selectedCharacter].mainphoto)} src={dataState[selectedCharacter].mainphoto}/>
                </div>
                <div id="name">
                    {dataState[selectedCharacter].name}
                </div>
                <div id="position">
                    {dataState[selectedCharacter].position}
                </div>
                <div id="photos">
                    {dataState[selectedCharacter].childphoto.map(item=> <>{childPhoto(item.photo)}</>)}
                </div>                
                <img onClick={()=>setopenModal(false)} className="close-modal" src="cross.svg"/>
            </div>
        </div>)
    }

    const dataHtml = dataState.map((item ,index)=>
        <>
           <img onClick={()=>openModalClick(index)} src={item.mainphoto}/>
        </>
        )

    return(
        <MainLayout>
           <div className="character">
                {dataHtml}
           </div>
           {openModal ? modalHtml() : ""}
        </MainLayout>
    )
}

export default Biography;