import MainLayout from "../layout";
import "../styles/music.scss"
import * as Constants from "../config";
import { useEffect, useState } from "react";

export default function Music(){

    const services = [{
        icon:"music_icon/spotify.png",
        name:"SPOTIFY",
        link:"https://open.spotify.com/artist/0fR69VNfdfW1QclKe6pfM6"
    },
    {
        icon:"music_icon/apple.png",
        name:"APPLE-MUSIC",
        link:"https://music.apple.com/us/artist/nabla9/1720055094"
    },
    {
        icon:"music_icon/amazonmusic.png",
        name:"AMAZON-MUSIC",
        link:"https://www.amazon.com/music/player/artists/B0C347692H/nabla9"
    },
    {
        icon:"music_icon/youtubemusic.png",
        name:"YOUTUBE-MUSIC",
        link:"https://music.youtube.com/channel/UCD1EkXrd_KswIEPKU9Cs22g"
    },
    ];

    const [dataState, setDataState] = useState([]);

    async function getData() {
        fetch(Constants.apiurl + Constants.getvideosPath)
        .then((response) => response.json())
        .then((data) => {
            data.reverse();
            setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    return(
        <MainLayout>
            <div className="music-content">
                {/* <div className="last-release-title">
                    <div>LAST RELEASE</div>
                </div> */}
                {dataState.map(i=>

                <div>
                    <div className="last-release-video">
                        <iframe src={i.link} frameborder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                            
                        </iframe>
                    </div>
                    <div className="streaming-icons-list">
                            {services.map(item=>
                                <a target="_blank" href={item.link}>
                                    <div>
                                        <img id={item.name} src={item.icon}/>
                                    </div>
                                </a>
                            )}
                    </div>
                </div>
                )}

                {/* <div className="gray-card">
                    <div className="streaming-list">
                        {services.map(item=>
                            <a target="_blank" href={item.link}>
                                <div>
                                    <img src={item.icon}/>
                                    <div>
                                        {item.name}
                                    </div>
                                </div>
                            </a>
                        )}
                    </div>
                </div> */}
                
            <div style={{marginBottom:"100px"}}></div>
            </div>
            
        </MainLayout>
    )
}