import { isMobile } from "react-device-detect";
import useWindowDimensions from "../tools/window_resolution";
import "./live.scss";
import { useEffect, useRef, useState } from "react";
import MainLayout from "../layout";
import * as Constants from "../config";
import TicketReservation from "../components/ticketreservation";
import {getDateFormat} from "../tools/datehelper";
import { useNavigate } from "react-router-dom";

function Live(){
    const anchorRef = useRef();
    const initialwidth = useWindowDimensions().width;
    const screenwidth = initialwidth < 375 ? 375 : initialwidth;
    const screenheight = useWindowDimensions().height;
    const contentwidth = screenwidth > 520 ? 500 
    : screenwidth - 100;

    const blockspace = 10;

    const livedata = [{
        date: new Date(2030,7,7),
        poster: "",
        livename: "WACKEN",
        place:"Germany",
        price: ""
    }]
    // {
    //     date: new Date('2024','03','08'),
    //     poster: "/posters/202338live.jpg",
    //     name: "A ghst of Flare & antiknock pre. [nevacoom vol.9]",
    //     place:"shinjuku antiknock",
    //     price: "adv Y3000+1D / DOOR Y3500"
    // },


    const [liveDataState, setLiveDataState] = useState(livedata);
    const [opacity, setOpacity] = useState(0);
    const [loading, setLoading] = useState(true);
    const [enlargeImageSource, setEnlargeImageSource] = useState("");
    const [reservationInProgress,setReservationInProgress] = useState(false);
    const [reservationLive,setReservationLive] = useState({});

    const prepareAndShowData=(data)=>{
        data.sort(function(a,b){
           return new Date(a.date) - new Date(b.date);
          });
        data.forEach(item=>{
            var filtered = data.filter(x=>x.date.getMonth() == item.date.getMonth());
            var isMinimumDate = true;
            for(var i in filtered){
                if(filtered[i].date.getDate() < item.date.getDate())
                {
                    isMinimumDate = false;
                    break;
                }
            }
            item.firstLiveInMonth = isMinimumDate;
        });
        setLiveDataState(data);
        if(window.location.hash != ""){
            setTimeout(() => {
                anchorRef.current.click();
              }, 500);            
        }
        console.log(data);
    };

    const content = {
        marginTop: isMobile ? "20px" : "100px", 
        marginBottom: screenheight - 100,
        opacity: opacity,
        transition:"all 1s",
       // backgroundColor:"rgba(0, 0, 0, 0.29)",
        width:contentwidth
      };

    const monthStyle = {
        width: contentwidth,
        backgroundColor: "black",
        height:"50px",
        color:"white",
        textAlign:"center",
        fontWeight:1000
    };

    const dayStyle = {        
        width: contentwidth/4,
        backgroundColor: "black",
        height:contentwidth * 3 / 8,
        color:"white",
        textAlign:"center",
        resize:"horizontal",
        containerType: "size",
        fontWeight:1000
    };

    const posterImgStyle = {      
        width: - blockspace + contentwidth * 3 / 4, 
        marginLeft: blockspace 
    };

    const infoStyle = {
        backgroundColor:"#8d8d8e",
        marginTop: blockspace,
        width: contentwidth,
        marginBottom:"20px"
    };

    const middleBlock = {
        display: "flex",
        marginTop: blockspace
    };

    const textBlock = {
        color:"black",
        paddingLeft:"20px",
        paddingTop:"5px",
        fontSize:"20px"
    };

    const nameStyle = {
        fontWeight:"200"
    };

    const placeStyle = {
        paddingTop: "3px",
        paddingBottom: "3px",
        fontSize:"25px",        
        fontWeight:500
    };

    const priceStyle = {         
    };

    const buttonStyle = {
        width: contentwidth/1.4,
    };

    const enlargeImageBlock = {
        display: enlargeImageSource !== "" ? "" :"none"
    };

    const enlargeImage = {
        width: screenwidth > 720 ? 700 : screenwidth - 40 
    };

    const videobackground = {
       width: "100%",
       maxWidth:"1000px"
    };

    const buttons = {
        display:"grid",
        justifyContent:"center",
        paddingBottom: "20px"
    };

    const enlarge=(source)=>{
        setEnlargeImageSource(source);
    };

    const closeEnlarge=()=>{
        setEnlargeImageSource("");
    };

    const videoLoaded=()=>{        
        setTimeout(() => {
            setOpacity(1);
        }, 2000);
        setLoading(false);
    }

    async function getData() {
        fetch(Constants.apiurl + Constants.getlivesPath)
        .then((response) => response.json())
        .then((data) => {
            data.forEach(item=>item.date=getDateFormat(item.date));
            prepareAndShowData(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const makeReservation = (live)=>{       
        setLoading(!loading); 
        window.scrollTo(0, 0);
        setReservationLive(live);
        setReservationInProgress(true);
    }

    const bookAnother = ()=>{
        setLoading(!loading); 
        setReservationLive({});
        setReservationInProgress(false);
    }
    
    const reservationHtml = ()=>{
        return(
            <TicketReservation livename={reservationLive.livename}
                               place={reservationLive.place}
                               price={reservationLive.price}
                               date={reservationLive.date}
                               time={reservationLive.time}
                               bookAnother={bookAnother}
            />
        )
    }

    const livelist = liveDataState.map(item => 
        <div key={item.livename}>
            <div id={item.livename} className="anchor"></div>
            <div style={{display:item.firstLiveInMonth ? "":"none"}}>
                <div style={monthStyle}>
                   <div className="center-content month-font"> 
                        { item.date.getFullYear() + "." + (item.date.getMonth() + 1)}
                   </div>
                </div>
            </div>
            <div style={middleBlock}>
                <div style={dayStyle}>
                    <div className="day-number">
                        {item.date.getDate()} 
                    </div>
                    <div className="day-word">
                        {item.date.toLocaleString('en', { weekday: 'short' }).toUpperCase()}
                    </div>
                </div>
                <img style={posterImgStyle} onClick={()=>enlarge(item.poster)} src={item.poster}/>
            </div>
            <div style={infoStyle}>
                <div style={textBlock}>
                    <div style={nameStyle}>{item.livename}</div>
                    <div style={placeStyle}>{item.place?.toUpperCase()}</div>
                    <div style={priceStyle}>{item.price?.toUpperCase()}</div>
                </div>
                <div style={buttons}>
                    <div className="button" onClick={()=>makeReservation(item)} style={buttonStyle}>
                        <div>
                            <div className="small-text">取り置き</div>
                            <div>MAKE RESERVATION</div>
                        </div>
                    </div>
                    <a href={item.buylink}>
                    <div className="button" style={buttonStyle}>
                        
                        <div>
                            <div className="small-text">チケットサイト</div>
                            <div>BUY TICKET</div>
                        </div>
                        
                    </div>
                    </a>
                </div>
            </div>
        </div>
        );
    
    return (
        <MainLayout displayLoading={loading}>        
        <video onLoadedData={videoLoaded} style={videobackground} autoPlay muted loop playsInline="true" disablePictureInPicture="true"> 
            <source src="bg-video.mp4" type="video/mp4"/>
        </video>
        <a ref={anchorRef} href={window.location.hash}>
        </a>
        <div onClick={closeEnlarge} style={enlargeImageBlock} className="modal">    
            <img style={enlargeImage} src={enlargeImageSource}/>  
        </div>
        <div style={content}>
         {!reservationInProgress ? livelist : ""}
         {reservationInProgress ? reservationHtml() : ""}
        </div>
        </MainLayout>
    )
}

export default Live;