import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../tools/window_resolution";
import Card from "./card";
import { Button } from "@mui/material";
import Uploading from "./uploading";
import { CloseOutlined } from "@mui/icons-material";
import * as Constants from "../config";
import axios from "axios";

export default function ImageUploader(props){

    const screenheight = useWindowDimensions().height;
    const screenwidth = useWindowDimensions().width;

    const modalwidth = screenwidth > 1000 ? 1000 : screenwidth - 60;

    const inputFile = useRef(null);

    const images = [{
        name:"logo182.png",
        link:"http://localhost:3000/logo192.png"
    }]

    const [imagesState,setImagesState] = useState([{}]);
    const [dataInProgressState,setDataInProgressState] = useState(false);
    

    const modalWindow = {
        width: modalwidth,
        height: screenheight*0.9,
        border:"solid 5px grey",
        borderRadius:"10px",
        backgroundColor:"white", 
        position:"fixed",  
        overflow:"auto",
        top:"5%",
        display: props.active ? "block" : "none",
        zIndex:"3"
    };

    const deleteIcon = {
        position:"fixed",
        top:"5px",
        right:"5px",
        cursor:"pointer",
        top:"7%",
        right: (screenwidth-modalwidth)/2 + 20
    };

    const iconStyle={
        fontSize:"40px"
    };

    const modalParent = {
        width: screenwidth,
        height: screenheight,
        position:"fixed",
        display: props.active ? "flex" : "none",
        justifyContent: "center",
        left:0,
        top:0,
        zIndex:"2",
        backgroundColor:"black",
        opacity:"50%"
    };


    const imagesStyle = {
        marginTop:"50px",
        overflow:"auto",    
        display: "inline-flex",
        flexWrap: "wrap",
        justifyContent:"center"
    }

    const addActionStyle = {
        position:"fixed",
        width:"200px",
        top:"7%",
        zIndex:"10",
        left: (screenwidth-modalwidth)/2 + 20
    };

    const title = {
        paddingBottom:"10px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "150px",
    };

    const uploadFile = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
      };

    const copyLink = (text) =>{
        var path = window.location.origin;
        navigator.clipboard.writeText(path + "/" + text);
    }

    const deleteImage = (name) => {
        setDataInProgressState(true);
        fetch(Constants.apiurl + Constants.deleteimagePath + "?name=" + name)
        .then((data) => {
            getData();
        })
        .catch((err) => {            
            setDataInProgressState(false);
            console.log(err.message);
        });
    };

    const imageHtml = (link,name) => {
        return(
            <>
                <div title={name} style={title}>{name}</div>
                <img width="150px" onClick={() => props.handleChoose(name)} src={link}/>
                <button onClick={()=>copyLink(name)} style={{display:"block"}} className="button">COPY LINK</button>
            </>
        );
    };

    const imagesHtml = imagesState.map(item=>
            <div key={item.link + item.name}>
                <Card backgroundColor={props.selectedImage===item.name ? "rgb(165 165 165)" : null} child={imageHtml(item.link,item.name)} id={item.name} handleDelete={deleteImage}/>
            </div>
        );

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if(!file) return;
        imagesState.forEach(item=>{
            if(item.name===file.name)
                {
                    alert("file already exist");
                    return; 
                }
        });
        const formData = new FormData();         
        setDataInProgressState(true);
        formData.append("file", file);
        axios
            .post(Constants.apiurl + Constants.imageUpload, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem("token")
            },
            })
            .then(response => {
                if(response.status == 401) window.location.reload(false);
                getData();
                return response.json();
            })
            .catch((error) => { 
                setDataInProgressState(false);
            });
        };

    const getData = () => { 
        setDataInProgressState(true);
        fetch(Constants.apiurl + Constants.imagesListPath)
        .then((response) => response.json())
        .then((data) => {
            setImagesState(data);            
            setDataInProgressState(false);
        })
        .catch((err) => { 
            setDataInProgressState(false);
            console.log(err.message);
        });
    }

    useEffect(() => {
        getData();
        }, []);

    return(
        <>
        <Uploading inprogress={dataInProgressState}/>
            <div style={modalParent} onClick={() => props.handleChoose("")}>
            </div>
            <div style={modalWindow}>
                <Button style={addActionStyle} onClick={uploadFile} variant="contained" color="secondary">Upload new</Button>
                <div style={imagesStyle}>
                    {imagesHtml}
                </div>
                <div style={deleteIcon}  onClick={() => props.handleChoose("")}>
                    <CloseOutlined style={iconStyle}/>
                </div>
            </div>
            <input type='file' id='file' onChange={handleFileUpload} accept="image/png, image/gif, image/jpeg" ref={inputFile} style={{display: 'none'}}/>
        </>
    );
}