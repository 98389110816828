import Countdown from "../components/countdown";
import MainLayout from "../layout";

export default function CountdownPage(){

    const zIndex = process.env.REACT_APP_HIDE_MENU == 'true' ? 100 : 5;

    return (        
     <div id="countdownpage" style={{zIndex:zIndex}}>
            <div id="countdown-block">
            <img src="hand_logo.png"/>
            <Countdown
                startDate="2025-01-10T00:00:00"
                endDate="2025-07-11T00:00:00"
                initialNumber={79685243244}
                finalNumber={0}
            />
            <Countdown
                startDate="2025-01-10T00:00:00"
                endDate="2025-07-02T00:00:00"
                initialNumber={824635215}
                finalNumber={0}
            />
            <Countdown
                startDate="2025-01-10T00:00:00"
                endDate="2025-06-03T00:00:00"
                initialNumber={9675432}
                finalNumber={0}
            />
            <Countdown
                startDate="2025-01-10T00:00:00"
                endDate="2025-04-05T00:00:00"
                initialNumber={98865}
                finalNumber={0}
            />
            <Countdown
                startDate="2025-01-10T00:00:00"
                endDate="2025-02-07T00:00:00"
                initialNumber={369}
                finalNumber={0}
            />
            </div>
        </div>
       
      );
}
