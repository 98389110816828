import { useEffect, useState } from "react";
import MainLayout from "../layout"
import "../styles/cart.scss"
import * as Constants from "../config";
import { addToCart, deleteFromCart, deleteProductFromCart, dropCart, getCart, getTotalCount, removeOldProducts } from "../tools/cartservice";
import { useNavigate } from "react-router-dom";
import OrderControls from "../components/order-controls";
import OrderOverview from "../components/order-overview";
import Loading from "../components/loading-screen";

export default function Cart(){

    const navigate = useNavigate();
    const stepCircle = "step-circle";
    const stepCircleCurrent = "step-circle current-step";

    const [currentStep, setCirrentStep] = useState(1);
    const data = [{
        id:0,
        name:"123",
        images:[]
    }];

    const [dataState,setDataState] = useState(data);
    const [order,setOrder] = useState([]);
    const [total,setTotal] = useState(0);
    const [price,setPrice] = useState(0);
    const [waribiki,setWaribiki] = useState(0);
    const [deliveryFee,setDeliveryFee] = useState(0);
    const [detailsData,setDetailsData] = useState(null);
    const [type,setType] = useState(Constants.takeatliveType);
    const [loading, setLoading] = useState(false);
    const [orderTypeNotSelected, setOrderTypeNotSelected] = useState(false);

    function createOrderObject(gdata){
        removeOldProducts(gdata);
        var cart = getCart();
        if(cart == null){
            setOrder(null);
        }
        for(var a in cart){
            const cindex = gdata.findIndex(x=>x.name==cart[a].name);
            if(cindex > -1)
                cart[a].item = gdata[cindex];
        }
        setOrder(cart);
        setDataState(gdata);
        calculateTotal(cart);
    }

    function detailsComplete(sdata){
        if(sdata == null) {
            setCirrentStep(1);
            return;
        }
        sdata.type = type;
        setDetailsData(sdata);
        setCirrentStep(3);
    }

    function saveDetailsData(sdata){
        console.log(sdata);
        setDetailsData(sdata);
        setCirrentStep(1);
    }

    function overviewComplete(sdata){
        if(!sdata) {
            setCirrentStep(2);
            return;
        }
        saveData();
        setLoading(true);
        dropCart();
        setCirrentStep(4);
    }

    function addItem(name){
        addToCart(name);
        createOrderObject(dataState);
    }

    function subItem(name){
        deleteFromCart(name);
        createOrderObject(dataState);
    }

    function goToStep2(){
        if(type === ""){
            setOrderTypeNotSelected(true);
            return;
        }
        setCirrentStep(2);
    }

    function calculateTotal(cart){
        var totalPrice = 0;
        for(var a in cart){
            totalPrice += parseInt(cart[a].item.price) * parseInt(cart[a].count);
        }
        setPrice(totalPrice);
        if(type===Constants.takeatliveType)
        {
            var wari = 0;
            order.forEach(element => {
                wari += element.count * element.item.waribiki;
            });
            totalPrice -= wari;
            setWaribiki(wari);
        }
        else{
            setWaribiki(0);
        }
        if(type==Constants.deliveryType && currentStep > 2){
            var delivery = 0;
            order.forEach(element => {
                delivery += element.item.haitatsu*1;
            });
            if(delivery > 1200)
                delivery=1200;
            totalPrice += delivery;
            setDeliveryFee(delivery);
        }
        else{
            setDeliveryFee(0);
        }
        setTotal(totalPrice);
    }

    async function getData() {
        fetch(Constants.apiurl + Constants.merchPath)
        .then((response) => response.json())
        .then((data) => {
            createOrderObject(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    };

    const saveData = () => {
        let savedataObj = {};
        setLoading(true);
        Object.assign(savedataObj,detailsData);
        savedataObj.date = new Date();
        savedataObj.merch = order.map(x=> ({"name":x.name,"price":x.item.price,"count":x.count}));
        savedataObj.price = price;
        savedataObj.total = total;
        savedataObj.type = type;
        savedataObj.delivery = deliveryFee;
        const body = Constants.requestBody(savedataObj);
        fetch(Constants.apiurl + Constants.createMerchOrder, body)
        .then(response => {
            setCirrentStep(4);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
        )
        .catch(err => {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            alert("Something went wrong. Try again later.")
        });
    };

    useEffect(() => {
        getData();
     }, []);

    useEffect(() => {        
        calculateTotal(order);
        if(type !== "") setOrderTypeNotSelected(false);
     }, [type,currentStep,order]);

    const goodslist = (image,name,count,price) => {
        return(            
            <tr>
                <td id="image-row">
                    <img src={image[0]}/>
                </td>
                <td id="name-row">
                    {name}
                </td>
                <td id="count-row">
                    <img style={{display:currentStep==1?"":"none"}} src="minus.svg" onClick={()=>subItem(name)} id="counter-element"/>
                    <div id="count-element">
                        {count}
                    </div>
                    <img style={{display:currentStep==1?"":"none"}} src="plus.svg" onClick={()=>addItem(name)} id="counter-element"/>
                </td>
                <td id="price-row">
                    <div>
                        ¥{price}                         
                    </div>
                    <div className="zeicomi-small-text">
                        ( 税込 )
                    </div>
                </td>
            </tr>
        )
    }

    const goodstable = () => {
        return(
            <table cellspacing="0" cellpadding="0" className="goods-table">
                <tbody>
                    {order.map(item=> goodslist(item.item.image,item.name,item.count,item.item.price))}
                </tbody>
            </table>
        )
    }

    const progress = () =>{
        return(
            <>
                    <div>
                        <div className={currentStep == 1 ? stepCircleCurrent : stepCircle}>
                            1
                        </div>
                        <div className="steps-text">
                            カート
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div className={currentStep == 2 ? stepCircleCurrent : stepCircle}>
                            2
                        </div>
                        <div className="steps-text">
                            支払-配送
                        </div>
                    </div>                    
                    <hr/>
                    <div>
                        <div className={currentStep == 3 ? stepCircleCurrent : stepCircle}>
                            3
                        </div>
                        <div className="steps-text">
                            確認
                        </div>
                    </div>                    
                    <hr/>
                    <div>
                        <div className={currentStep == 4 ? stepCircleCurrent : stepCircle}>
                            4
                        </div>
                        <div className="steps-text">
                            完了
                        </div>
                    </div>
            </>
        )
    }

    function typeChanged(e) {
        setType(e.currentTarget.value);
    }
    
    function type1Buttonjapanesetext(){
        return(
            <div className="small-text">お取り置きに進む</div>
        )
    }
    function type2Buttonjapanesetext(){
        return(
            <div className="small-text">レジに進む</div>
        )
    }

    const gifska = () => {
        return(
            <img className="gif-choose-type" src="giphy.GIF"/>
        )
    }

    const goodsHtml =()=>{
        return(
            <>
                <div id="goods">
                    {order != null && order.length > 0 ? goodstable() :"Your cart is empty"}
                </div>
                <div style={{display: waribiki > 0?"":"none"}} id="waribiki">
                    <label id="waribiki-title">会場受取現金払い割引</label>
                    <label id="waribiki-number">-¥{waribiki}</label>
                </div>
                <div style={{display: deliveryFee>0 ?"":"none"}} id="waribiki">
                    <label id="waribiki-title">配送料</label>
                    <label id="waribiki-number">¥{deliveryFee}</label>
                </div>
                <div className="inline-inner-div total-price-block">
                    <div className="total-details-text">
                        {currentStep==1?"小計":"合計"}                        
                    </div>
                    <div className="bold-st-number">¥{total}</div>
                    <div className="zeicomi-small-text">( 税込 )</div>
                </div>
            </>
        )
    }
    
    const firstStep = () => {
        return(
            <>
                {orderTypeNotSelected ? gifska() : ""}
                <div id="choosetype">
                    
                    <div id="title" style={{color: orderTypeNotSelected? "red": "black"}}>
                        選択してください
                    </div>
                    <div className="radio-block">
                        <div id="takeatshow">
                            <label>
                                <input name="type" checked={type === Constants.takeatliveType} onChange={typeChanged} type="radio" value={Constants.takeatliveType}/>
                                <div id="radio-title">
                                    <i>
                                        会場受取現金払い(10%OFF!!)
                                    </i>
                                </div>
                            </label>
                        </div>
                        <div id="delivery">
                            <label>
                                <input name="type" disabled checked={type === Constants.deliveryType} onChange={typeChanged} type="radio" value={Constants.deliveryType}/>
                                <div id="radio-title">
                                    <i>
                                    配送
                                    </i>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                {goodsHtml()}
                <div id="buttons">
                    <div className="button" onClick={()=>navigate("/merch")}>
                        <div>
                            <div className="small-text">お買い物を続ける</div>
                            <div>BACK TO SHOPPING</div>
                        </div>
                    </div> 
                    <div className="button" onClick={()=>
                        {
                            if(order == null || order.length == 0) return;
                            goToStep2();
                        }
                        }>
                        <div>
                            {type===Constants.takeatliveType ? type1Buttonjapanesetext() : type2Buttonjapanesetext()}
                            <div>NEXT</div>
                        </div>
                    </div> 
                </div>
            </>
        )
    }

    const secondStep = () =>{
        return(
            <OrderControls type={type}
             detailsComplete={detailsComplete} saveDetailsData={saveDetailsData} data={detailsData}/>
        )
    }

    const thirdStep = () =>{
        return(
            <OrderOverview 
             overviewComplete={overviewComplete} goods={goodsHtml} data={detailsData}/>
        )
    }

    const deliveryHeader = () => {
        return(
            <>
                ご注文が完了しました
                <div>
                    Your oder has been confirmed
                </div>
            </>
        )
    }

    const deliveryJP = () => {
        return(
            <>
                ご注文ありがとうございます。
                <br/>
                ご入力いただきましたメールアドレスにご注文完 了メールをお送りいたしましたのでご確認をお願い いたします。
                <br/>
                注文内容に誤りがある、 注文完了メールが届かない 場合にはお手数ですが CONTACT ページからのご連絡 をお願いいたします。
                <br/>
                配送状況に関しましては発送完了メールに記載されて おります追跡番号から各配送業者にご確認いただけま すようお願いいたします。
                <br/>
                それでは商品の発送まで今しばらくお持ちくださいま せ!
                <br/>
            </>
        )
    }
    const deliveryEN = () => {
        return(
            <>
                Thank you for your order.
                <br/>
                We have sent an order confirmation e-mail to the e-mail address you entered.
                <br/>
                If there is some error in your order, or you do not receive
                the order confirmation e-mail, please contact us using CONTACT page.
                <br/>
                To check the delivery status, please check with the shipping company using the tracking number in the shipment confirmation e-mail.
                <br/>
            </>
        )
    }


    const takeHeader = () => {
        return(
            <>  
                マーチのお取り置きが完了しました
                <div>Merch reservation complete</div>
            </>
        )
    }

    const takeJP = () => {
        return(
            <>  
                ご予約ありがとうございます。
                <br/>
                公演当日、会場物販スタッフにご入力いただきまし た予約名をお伝えください。
                <br/>
                会場でお会いできることを楽しみにしております。
                <br/>
                <br/>
                ※ご入力いただきましたメールアドレスにご予約完 了メールをお送りいたしましたのでご確認ください。
                <br/>
            </>
        )
    }

    const takeEN = () => {
        return(
            <>  
                Thank you for your reservation.
                <br/>
                When you come to the show please tell Nabla9 merch's staff the name of reservation you entered.
                <br/>
                We look forward to seeing you at the show.
                <br/>
                <br/>
                *We have sent an email to the email address you entered to confirm your reservation.
            </>
        )
    }

    const goBackButtons=(header,jp,en)=>{
        return(            
            <div className="gray-card">
                <div id="confirm-border">
                    <img src="home_button.png" className="confirmation-page-logo" />
                    <div id="header">
                        {header}
                    </div>
                    <div className="text-center confirmation-text">
                        <div className="japanese-confirmation-text">
                            {jp}
                        </div>
                        <div >
                            {en}
                        </div>
                    </div>
                    <div className="go-back-buttons">
                        <button onClick={()=>navigate("/")} className="button">GO HOME PAGE</button>
                    </div>
                </div>
            </div>
        )
    }

    const mainBlock = () => {
        return(
            <>
            <div className="gray-card">
                <div id="cart">
                    <img src="cart.png"/>
                    <div>MY CART</div>
                </div>
                <div id="steps">
                    {progress()}
                </div>
                <div className="cart-progress-content">
                   {currentStep==1 ? firstStep():""}
                   {currentStep==2 ? secondStep():""}
                   {currentStep==3 ? thirdStep():""}
                </div>
            </div>
            </>
        )
    }

    return(
        <MainLayout>
            <div style={{display:loading ? "" : "none"}}>
                <Loading />
            </div>
            {currentStep==4 && type==Constants.deliveryType ? 
                goBackButtons(deliveryHeader(),deliveryJP(),deliveryEN()):
                currentStep==4 && type==Constants.takeatliveType ? 
                goBackButtons(takeHeader(),takeJP(),takeEN()) 
                : mainBlock()}
        </MainLayout>
    )
}