import React, { useState, useEffect } from 'react';

const Countdown = ({ startDate, endDate, initialNumber, finalNumber }) => {
  const [currentNumber, setCurrentNumber] = useState(initialNumber);

  useEffect(() => {
    // Преобразуем строки в объекты Date
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Если дата начала позже конца, ничего не делаем
    if (start >= end) {
      console.error('Start date must be earlier than end date');
      return;
    }

    const interval = setInterval(() => {
      const currentTime = new Date();

      // Если текущее время позже конца, останавливаем таймер
      if (currentTime >= end) {
        setCurrentNumber(finalNumber);
        clearInterval(interval);
        return;
      }

      // Если текущее время раньше начала, оставляем начальное значение
      if (currentTime < start) {
        setCurrentNumber(initialNumber);
        return;
      }

      // Вычисляем процент прошедшего времени между стартом и концом
      const totalTime = end - start;
      const elapsedTime = currentTime - start;
      const progress = elapsedTime / totalTime;

      // Рассчитываем текущее значение числа
      const newNumber = initialNumber - progress * (initialNumber - finalNumber);

      // Округляем до ближайшего целого числа и гарантируем, что оно не меньше конечного значения
      setCurrentNumber(Math.max(Math.round(newNumber), finalNumber));
    }, 50); // обновляем каждую секунду

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, [startDate, endDate, initialNumber, finalNumber]);

  // Количество цифр в начальном значении
  const numDigits = initialNumber.toString().length;

  // Форматируем число с нужным количеством ведущих нулей
  const formattedNumber = currentNumber.toFixed(0).padStart(numDigits, '0');

  return (
    <div>
      <h1>{formattedNumber}</h1>
    </div>
  );
};

export default Countdown;