import { Button, TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import { Dataset } from "@mui/icons-material";
import uuidv4 from "../tools/guidgenerator";

export default function MembersConfig(){
    
    const [dataState, setDataState] = useState([]);

    const cpict = [1,2,3,4,5,6,7,8,9];

    const [uploadPropertyState, setUploadPropertyState] = useState("");

    const [uploaderActiveState, setUploaderActiveState] = useState(false);
   
    const [selectedImage, setSelectedImage] = useState("");

    const deleteData = (id) =>{
        if (window.confirm('Are you sure you want to delete?')) {
            fetch(Constants.apiurl + Constants.deleteMember + "?id=" +id, Constants.requestHeaderOnly())
            .then((response) => getData())
            .catch((err) => {
               console.log(err.message);
            });
        }
    };
    
    const addData = () =>{
        let newid = uuidv4();
        const newElement = {id:newid};
        const newData = [newElement].concat(dataState);
        console.log(newData);
        setDataState(newData);
    };

    const onChangeValue = (e) => {
        const { id, name, value } = e.target;
        setDataState(ChangeArray(dataState, id, name, value));     
    };


    const imageSelected = (filename) => {
        if(filename !== "" && filename !== undefined){
            var e = {
                target:{
                    id:uploadPropertyState.id,
                    name:uploadPropertyState.name,
                    value:filename
                }
            };
            onChangeValue(e);
        }
        setUploaderActiveState(false);
    };

    const selectImage = (e) => {
        const newE = e.target;
        setSelectedImage(e.target.value);
        setUploadPropertyState(newE);
        setUploaderActiveState(true);
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.getmembersPath)
        .then((response) => response.json())
        .then((data) => {
            setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const saveData = (data) => {
        const body = Constants.requestBody(data);
        fetch(Constants.apiurl + Constants.setmembersPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => {
            getData();
        })
        .catch(err => {
            console.log(err.error);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const inputsHtml = (id,item) => {
        return(
            <div key={id+"membersxpanded"}>
                <div className="input-block">
                    <div className="input-title">name:</div>
                    <input id={id} type="name" onChange={onChangeValue} name="name" label="name" variant="outlined" value={item.name} />
                </div>               
                <div className="input-block">
                    <div className="input-title">position:</div>
                    <input id={id} type="name" onChange={onChangeValue} name="position" label="position" variant="outlined" value={item.position} />
                </div>               
                <div className="input-block">
                    <div className="input-title">Main photo:</div>
                    <input title={item.mainphoto} id={id} style={{"cursor":"pointer"}} onClick={selectImage} name="mainphoto" readOnly label="mainphoto" variant="outlined" value={item.mainphoto} />
                </div> 
                <div className="input-block">
                    <div className="input-title">Picture 1:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture1"} readOnly label="picture1" value={item.picture1} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 2:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture2"} readOnly label="picture2" value={item.picture2} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 3:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture3"} readOnly label="picture3" value={item.picture3} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 4:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture4"} readOnly label="picture4" value={item.picture4} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 5:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture5"} readOnly label="picture5" value={item.picture5} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 6:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture6"} readOnly label="picture6" value={item.picture6} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 7:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture7"} readOnly label="picture7" value={item.picture7} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 8:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture8"} readOnly label="picture8" value={item.picture8} variant="outlined" />
                </div>
                <div className="input-block">
                    <div className="input-title">Picture 9:</div>
                    <input id={id} style={{"cursor":"pointer"}} onClick={selectImage} name={"picture9"} readOnly label="picture9" value={item.picture9} variant="outlined" />
                </div>
                <Button style={{margin:"10px", width:"100px"}} onClick={()=>saveData(item)} variant="contained" color="success">保存</Button>
            </div>
        )
    };

    const inputsHtmlClosed = (id,name) =>{
        return(
            <div key={id+"memberclosed"}>
                <div>{name}</div>
            </div>
        )
    }

    const dataHtml = dataState.map(item=> 
            <Card key={item.id + item.image} 
            child={inputsHtml(item.id,item)} 
            childClosed={inputsHtmlClosed(item.id,item.name)} 
            id={item.id} 
            expandable={true}
            handleDelete={deleteData} />
        )


    return(
        <>
            <ImageUploader selectedImage={selectedImage} handleChoose={imageSelected} active={uploaderActiveState}/>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData} addAction={addData} />
            </div>
            {dataHtml}
        </>
    )
}