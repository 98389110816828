import { Button, TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import Jodit from "./jodit";
import uuidv4 from "../tools/guidgenerator";
import { Dataset } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export default function NewsConfig(){
    
    const data = [{
        id:0,
        date: "",
        type: "",
        title: "",
        text:"",
    }];

    const [dataState, setDataState] = useState(data);

    const [uploadPropertyState, setUploadPropertyState] = useState("");

    const [uploaderActiveState, setUploaderActiveState] = useState(false);
   
    const [selectedImage, setSelectedImage] = useState("");

    const [currentNewsText, setCurrentNewsText] = useState();
    const [currentNewsId, setCurrentNewsId] = useState();

    const deleteData = (id) =>{
        if (window.confirm('Are you sure you want to delete?')) {
            fetch(Constants.apiurl + Constants.deletenewsPath + "?id=" +id, Constants.requestHeaderOnly())
            .then((response) => getData())
            .catch((err) => {
               console.log(err.message);
            });
        }
    };
    
    const addData = () =>{
        let newid = uuidv4();
        const newElement = {id:newid, type:"INFORMATION"};
        setDataState([newElement, ...dataState]);
    };

    const onChangeValue = (e) => {
        const { id, name, value } = e.target;
        setDataState(ChangeArray(dataState, id, name, value));    
    };


    const imageSelected = (filename) => {
        if(filename !== "" && filename !== undefined){
            var e = {
                target:{
                    id:uploadPropertyState.id,
                    name:uploadPropertyState.name,
                    value:filename
                }
            };
            onChangeValue(e);
        }
        setUploaderActiveState(false);
    };

    const selectImage = (e) => {
        const newE = e.target;
        setSelectedImage(e.target.value);
        setUploadPropertyState(newE);
        setUploaderActiveState(true);
    };

    
    const setTextContent = (text) =>{
        setCurrentNewsText(text);
    }

    const getData = () => {
        fetch(Constants.apiurl + Constants.newsPath)
        .then((response) => response.json())
        .then((data) => {
            setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const saveData = (data) => {
        const body = Constants.requestBody(data);
        fetch(Constants.apiurl + Constants.setnewsPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => {
            getData();
        })
        .catch(err => {
            console.log(err.error);
        });
    }

    useEffect(() => {        
        getData();
     }, []);

    const openTextEditor = (id, text)=>
    {
        setCurrentNewsId(id);
        setCurrentNewsText(text);
    }

    const setPublishAndSave = (item)=>
    {
        var updateItem = {};
        Object.assign(updateItem, item);
        updateItem.publish = true;
        saveData(updateItem);
    }

    const textComplete = ()=>
    {
        setDataState(ChangeArray(dataState, currentNewsId, "text", currentNewsText)); 
        setCurrentNewsId("");
        setCurrentNewsText("");
    }

    const inputsHtml = (id,date,type,title,text,item) => {
        return(
            <div key={id+"newsexpanded"}>
                <div className="input-block">
                    <div className="input-title">Type:</div>
                    <select id={id}  onChange={onChangeValue} name="type" label="type" variant="outlined" value={type} >
                        <option>LIVE</option>
                        <option>INFORMATION</option>
                        <option>MEDIA</option>
                    </select>
                </div>
                <div className="input-block">
                    <div className="input-title">Date:</div>
                    <input id={id} type="date" onChange={onChangeValue} name="date" label="date" variant="outlined" value={date} />
                </div>
                <div className="input-block">
                    <div className="input-title">Title:</div>
                    <input id={id}  onChange={onChangeValue} name="title" label="title" variant="outlined" value={title} />
                </div>
                <div className="input-block">
                    <div className="input-title">Text:</div>
                    <input onClick={()=>openTextEditor(id,text)} className="pointer" id={id} name="text" label="text" variant="outlined" readOnly />
                </div>
                <Button style={{margin:"10px", width:"100px"}} onClick={()=>saveData(item)} variant="contained" color="success">保存</Button>                
                <Button style={{margin:"10px", width:"100px"}} disabled={item.publish} onClick={()=>setPublishAndSave(item)} variant="contained" color="success">PUBLISH</Button>
            </div>
        )
    };

    const newsClosedHtml =(id,date,title) => {
        return(
            <>
                <div key={id+"newsclosed"}>
                    <div>{date}</div>
                    <div className="livename-overflow-hide">{title}</div>          
                </div>
            </>
        )
    }

    const dataHtml = dataState.map(item=> 
            <Card key={item.id + item.image} 
            child={inputsHtml(item.id,item.date,item.type,item.title,item.text,item)} 
            childClosed={newsClosedHtml(item.id,item.date,item.title)}
            id={item.id} 
            expandable={true}
            backgroundColor={!(item.publish)? "rgb(108 10 10)" : ""}
            handleDelete={deleteData} />
        )


    const modaltexteditor = () =>{
        return(            
            <div className="modal-text-editor">
                <Jodit textComplete={textComplete} setTextContent={setTextContent} content={currentNewsText} id={currentNewsId}  />
            </div>
        )
    }

    return(
        <>
            {!!(currentNewsId) ? modaltexteditor():""}
            <ImageUploader selectedImage={selectedImage} handleChoose={imageSelected} active={uploaderActiveState}/>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData} addAction={addData} />
                <div style={{backgroundColor:"black", borderRadius:"5px", padding:"8px", margin:"5px 5px 5px 5px", display:"inline-block"}}>
                    <a style={{color:"white"}} href="/news?type=unpublished" target="_blank">Check Unpublished</a>
                </div>
            </div>
            {dataHtml}
        </>
    )
}