import React from 'react';
import BombButton from './bomb-button';
import {useState, useEffect} from 'react';
import {isIOS, isMobile} from 'react-device-detect';
import useWindowDimensions from '../tools/window_resolution'
import { useNavigate } from 'react-router-dom';
import isIphone from '../tools/isiphone';
import '../styles/menu_button.scss'


function Bottle(){

    const navigate = useNavigate();
    
    const [offset, setOffset] = useState(0);

    const [isButtonMO, setIsButtonMO] = useState(false);
    
    const [MObuttonName, setMObuttonName] = useState("");

    const [buttonPositions, setButtonPositions] = useState([]);
    const initialwidth = useWindowDimensions().width;
    const screenwidth = initialwidth < 375 ? 375 : initialwidth;

    const bombsize = screenwidth > 720 ? 700 
                    : screenwidth - 20;

    const [moveY, setMoveY] = useState(5);
    
    const smallButtonSize = 70;
    const standardButtonSize = 90;
    const bigButtonSize2 = 140;
    const bigButtonSize = 160;
    const moButtonSize = 240;

    const [newsbuttonSize, setnewsbuttonSize] = useState(standardButtonSize);
    const [liveButtonSize, setliveButtonSize] = useState(bigButtonSize);
    const [bioButtonSize, setbioButtonSize] = useState(smallButtonSize);
    const [musicButtonSize, setmusicButtonSize] = useState(bigButtonSize2);
    const [contactButtonSize, setcontactButtonSize] = useState(standardButtonSize);
    const [merchButtonSize, setmerchButtonSize] = useState(standardButtonSize);

    const newsbuttonname = "NEWS";
    const livebuttonname = "LIVE";
    const musicbuttonname = "MUSIC";
    const biobuttonname = "BIO";
    const contactbuttonname = "CONTACT";
    const merchbuttonname = "MERCH";


    const buttons = [newsbuttonname,livebuttonname,musicbuttonname,contactbuttonname,merchbuttonname,biobuttonname];

    const newsbuttonstyle={
        paddingTop: 
            MObuttonName === newsbuttonname && isButtonMO ? "35%" 
            : MObuttonName === musicbuttonname && isButtonMO ? "30%" //music
            : MObuttonName === livebuttonname && isButtonMO ? "26%" //live
            : MObuttonName === contactbuttonname && isButtonMO ? "26%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "27%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "34%" //bio 
            : MObuttonName !== newsbuttonname && isButtonMO ? "22%"
            : "23%", //"27%"
        paddingLeft:  
            MObuttonName === newsbuttonname && isButtonMO ? "35%" 
            : MObuttonName === musicbuttonname && isButtonMO ? "22%" //music
            : MObuttonName === livebuttonname && isButtonMO ? "55%" //live
            : MObuttonName === contactbuttonname && isButtonMO ? "44%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "42%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "22%" //bio 
            : MObuttonName !== newsbuttonname && isButtonMO ? "22%"
            : "35%",//"43%",
        position: "absolute",
        zIndex: "5",
        transition: "all 0.5s",
    }
    
    const musicbuttonstyle={
        paddingTop:  
            MObuttonName === musicbuttonname && isButtonMO ? "41%"             
            : MObuttonName === newsbuttonname && isButtonMO ? "56%"  //news
            : MObuttonName === livebuttonname && isButtonMO ? "38%" //live
            : MObuttonName === contactbuttonname && isButtonMO ? "35%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "32%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "23%" //bio 
            : MObuttonName !== musicbuttonname && isButtonMO ? "47%"
            : "33%", //"50%",
        paddingLeft:
            MObuttonName === musicbuttonname && isButtonMO ? "48%"            
            : MObuttonName === newsbuttonname && isButtonMO ? "70%" //news
            : MObuttonName === livebuttonname && isButtonMO ? "66%" //live
            : MObuttonName === contactbuttonname && isButtonMO ? "59%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "58%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "35%" //bio 
            : MObuttonName !== musicbuttonname && isButtonMO ? "67%"
            : "51%",//"62%",      
        position: "absolute",
        zIndex: "5",
        transition: "all 0.5s",
    }
    
    const livesbuttonstyle={
        paddingTop: 
            MObuttonName === livebuttonname && isButtonMO ? "47%"       
            : MObuttonName === newsbuttonname && isButtonMO ? "66%" //news        
            : MObuttonName === musicbuttonname && isButtonMO ? "45%"//music
            : MObuttonName === contactbuttonname && isButtonMO ? "26%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "32%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "50%" //bio 
            : "40%",//"42%",
        paddingLeft:
            MObuttonName === livebuttonname && isButtonMO ? "25%"       
            : MObuttonName === newsbuttonname && isButtonMO ? "16%" //news     
            : MObuttonName === musicbuttonname && isButtonMO ? "15%" //music
            : MObuttonName === contactbuttonname && isButtonMO ? "26%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "26%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "15%" //bio 
            : "14%",//"24%",      
        position: "absolute",
        zIndex: "5",
        transition: "all 0.5s",
    }
    
    const biobuttonstyle={
        paddingTop:  
            MObuttonName === merchbuttonname && isButtonMO ? "44%"     
            : MObuttonName === newsbuttonname && isButtonMO ? "77%" //news           
            : MObuttonName === musicbuttonname && isButtonMO ? "74%" //music 
            : MObuttonName === livebuttonname && isButtonMO ? "70%" //live
            : MObuttonName === contactbuttonname && isButtonMO ? "50%" //contact
            : MObuttonName === biobuttonname && isButtonMO ? "48%"  //bio 
            : "59%",//"64%",
        paddingLeft:
            MObuttonName === merchbuttonname && isButtonMO ? "70%"      
            : MObuttonName === newsbuttonname && isButtonMO ? "46%" //news      
            : MObuttonName === musicbuttonname && isButtonMO ? "29%"  //music  
            : MObuttonName === livebuttonname && isButtonMO ? "63%" //live 
            : MObuttonName === contactbuttonname && isButtonMO ? "67%" //contact
            : MObuttonName === biobuttonname && isButtonMO ? "46%" //bio 
            : "47%",//"40%",      
        position: "absolute",
        zIndex:"5",
        transition: "all 0.5s",
    }

    const merchbuttonstyle={
        paddingTop:   
            MObuttonName === biobuttonname && isButtonMO ? "66%"     
            : MObuttonName === newsbuttonname && isButtonMO ? "70%" //news                           
            : MObuttonName === musicbuttonname && isButtonMO ? "79%" //music
            : MObuttonName === livebuttonname && isButtonMO ? "55%" //live
            : MObuttonName === contactbuttonname && isButtonMO ? "68%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "57%"  //merch 
            : "70%",//"70%",
        paddingLeft:   
            MObuttonName === biobuttonname && isButtonMO ? "19%"     
            : MObuttonName === newsbuttonname && isButtonMO ? "61%" //news                
            : MObuttonName === musicbuttonname && isButtonMO ? "45%" //music
            : MObuttonName === livebuttonname && isButtonMO ? "67%" //live
            : MObuttonName === contactbuttonname && isButtonMO ? "65%" //contact
            : MObuttonName === merchbuttonname && isButtonMO ? "37%"  //merch 
            : "57%",//"57%",      
        position: "absolute",
        zIndex: "5",
        transition: "all 0.5s",
    }
    
    const contactbuttonstyle={
        paddingTop:   
            MObuttonName === contactbuttonname && isButtonMO ? "53%"       
            : MObuttonName === newsbuttonname && isButtonMO ? "74%" //news            
            : MObuttonName === musicbuttonname && isButtonMO ? "62%" //music
            : MObuttonName === livebuttonname && isButtonMO ? "80%" //live 
            : MObuttonName === merchbuttonname && isButtonMO ? "44%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "78%" //bio 
            : "73%",//"86%",
        paddingLeft:   
            MObuttonName === contactbuttonname && isButtonMO ? "29%"   
            : MObuttonName === newsbuttonname && isButtonMO ? "30%" //news                  
            : MObuttonName === musicbuttonname && isButtonMO ? "17%" //music
            : MObuttonName === livebuttonname && isButtonMO ? "50%" //live 
            : MObuttonName === merchbuttonname && isButtonMO ? "15%" //merch 
            : MObuttonName === biobuttonname && isButtonMO ? "31%" //bio 
            : "27%",//"25%",     
        position: "absolute",
        zIndex:"5",
        transition: "all 0.5s",
    }
    
    const style = {
        backgroundImage:"url(bottle.png)",
        height: bombsize + "px",
        width: bombsize + "px",
        marginTop: moveY,
        marginLeft:"1.5%",
        transition:"margin-top 2s ease-in-out",
        backgroundSize: "cover",
        backgroundRepeat: "round",
        color:"white",
        position:"absolute",
    }   

    const stylepseudobottle = {
        height: bombsize + "px",
        width: bombsize + "px",
        position:"relative",
    }    

    setTimeout(() => {
        setMoveY(-moveY);
      }, 2000); 

    const renderContent =() =>{
        setbuttonsSize();
        getButtonsPositions();
        setIsButtonMO(false);
        setMObuttonName("");
    }

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            renderContent();
          }, 50);
     }, [screenwidth]);

    useEffect(() => {
        getButtonsPositions();
     }, [offset]);

    useEffect(() => {
        setbuttonsSize();
        getButtonsPositions();        
        setTimeout(() => {
            getButtonsPositions();
          }, 250);     
        setTimeout(() => {
            getButtonsPositions();
          }, 500);
     }, [MObuttonName]);

    const setbuttonsSize = () => {
        var scale = 500/bombsize;
        var size = isButtonMO && MObuttonName === musicbuttonname ? moButtonSize 
                    :isButtonMO && MObuttonName !== musicButtonSize ? smallButtonSize
                    :bigButtonSize2;
        setmusicButtonSize(size / scale);
        size = isButtonMO && MObuttonName === newsbuttonname ? moButtonSize
                    :isButtonMO && MObuttonName !== newsbuttonname ? smallButtonSize
                    :standardButtonSize;
        setnewsbuttonSize(size / scale);
        size = isButtonMO && MObuttonName === livebuttonname ? moButtonSize
                    :isButtonMO && MObuttonName !== livebuttonname ? smallButtonSize
                    :bigButtonSize;
        setliveButtonSize(size / scale);
        size = isButtonMO && MObuttonName === merchbuttonname ? moButtonSize
                    :isButtonMO && MObuttonName !== merchbuttonname ? smallButtonSize
                    :standardButtonSize;
        setmerchButtonSize(size / scale);
        size = isButtonMO && MObuttonName === biobuttonname ? moButtonSize
                    :isButtonMO && MObuttonName !== biobuttonname ? smallButtonSize
                    :smallButtonSize;
        setbioButtonSize(size / scale);
        size = isButtonMO && MObuttonName === contactbuttonname ? moButtonSize
                    :isButtonMO && MObuttonName !== contactbuttonname ? smallButtonSize
                    :standardButtonSize;
        setcontactButtonSize(size / scale);
    }

    const buttonClick = (e) => {
        if(isMobile)
            mo(e);
        else if(isButtonMO && MObuttonName !== ""){
            navigate("/" + MObuttonName.toLowerCase());
        }
        
    }

    const mo = (e) => {
        //if(isMobile)return;
        //console.log("iteration:" + isButtonMO);
        for (let info of buttonPositions) {
            let deltaX = e.pageX - info.centerX;
            let deltaY = e.pageY - info.centerY;            
            if(window.scrollY)
                deltaY = deltaY - window.scrollY;
            if (deltaX * deltaX + deltaY * deltaY <= info.radius2 + 500) {
                if(MObuttonName === info.elem.id && isButtonMO){
                        if(isMobile) 
                        {
                            navigate("/" + MObuttonName.toLowerCase());     
                        }
                        return;
                }
                else{                    
                    setMObuttonName(info.elem.id);
                    setIsButtonMO(true);
                    return;
                }
            } else {
                if(MObuttonName === info.elem.id)
                {  
                    setMObuttonName(""); 
                    setIsButtonMO(false); 
                    return;  
                }
            }
        }
    };

    const getButtonsPositions = () =>{
        var butonpos = [];
        buttons.forEach(id => {
            let info = getCircleInfo(id);
            butonpos.push(info);

        });
        setButtonPositions(butonpos);
    }

    function getCircleInfo(id) {
        let elem = document.getElementById(id);
        if(!elem) return;
        let rect = elem.getBoundingClientRect();
        let radius = (rect.right - rect.left) / 2;
        return {
            elem: elem,
            centerX: (rect.right + rect.left) / 2,
            centerY: (rect.bottom + rect.top) / 2,
            radius2: radius * radius
        };
    }

    return(
        <div style={stylepseudobottle} onClick={buttonClick} onMouseMove={!isMobile ? mo : null}>
            <div style={style}>
                <img src="nabla9-text.png" className='nabla9-text-bottle'/>
                <div style={newsbuttonstyle} >
                    <div style={{display:"content"}} >
                        <BombButton id={newsbuttonname} 
                        shadowclass="news-button-shadow" 
                        name={newsbuttonname} link="/news" 
                        size={newsbuttonSize} 
                        mousovered={MObuttonName} scale="1"/>    
                    </div>
                </div>
                <div style={livesbuttonstyle}>                            
                    <div style={{display:"content"}} >
                        <BombButton id={livebuttonname} shadowclass="live-button-shadow" name={livebuttonname} link="/lives" size={liveButtonSize} mousovered={MObuttonName} scale="1"/>    
                    </div>
                </div>            
                <div style={musicbuttonstyle}>                            
                    <div style={{display:"content"}} >
                        <BombButton id={musicbuttonname} shadowclass="music-button-shadow" name={musicbuttonname} link="/music" size={musicButtonSize} mousovered={MObuttonName} scale="1" />    
                    </div>
                </div>                      
                <div style={merchbuttonstyle}>                            
                    <div style={{display:"content"}} >
                        <BombButton id={merchbuttonname} shadowclass="merch-button-shadow" name={merchbuttonname} size={merchButtonSize} mousovered={MObuttonName} scale="1" link="/merch" />    
                    </div>
                </div>          
                <div style={biobuttonstyle}>                            
                    <div style={{display:"content"}} >
                        <BombButton id={biobuttonname} shadowclass="bio-button-shadow" name={biobuttonname} size={bioButtonSize} mousovered={MObuttonName} scale="1" link="/bio" />    
                    </div>
                </div>       
                <div style={contactbuttonstyle}>                            
                    <div style={{display:"content"}} >
                        <BombButton id={contactbuttonname} shadowclass="contact-button-shadow" name={contactbuttonname} size={contactButtonSize} mousovered={MObuttonName} scale="1" link="/contact" />    
                    </div>    
                </div>
            </div>
        </div>
    );
}

export default Bottle;