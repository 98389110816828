import parse from 'html-react-parser'
export default function NewsCard(props){
    return(
            <div className="news-block">
                <div className="resize-block">                    
                    <div className="news-body-block">
                        <img src="home_button.png" className="news-page-logo"/>
                        <div className="news-body-content">
                            {parse( props.text)}
                        </div>
                    </div>
                    <div onClick={props.showNews} className="header-text pointer">
                        GO BACK TO NEWS
                    </div> 
                </div>
            </div>
    )
}