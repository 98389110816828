import { useEffect, useState } from "react";
import * as Constants from "../config";
import { getDateFormat } from "../tools/datehelper";

export default function OrderOverview(props){

    const [liveInfo, setliveInfo] = useState([]);

    function detail(name,jp,value){
        return(
        <>
            <div id="detail-title">
                {name}
                <label>{jp}</label>
            </div>
            <div id="detail-value">
                {value}
            </div>
        </>
        )
    }

    function deliveryDetailsHtml(){
        return(
            <>    
                <div className="overview-block">
                    <div id="overview-title">注文内容確認</div>
                    <div id="overview-goods">
                        <div id="title" className="iverview-center-title">
                            ご注文内容
                        </div>
                        {props.goods()}
                    </div>      
                    <div id="details">
                        <div className="iverview-center-title">
                            お払い方法
                        </div>
                        <label>代金引換のみ</label>
                    </div>                                    
                    <div id="details">
                        <div className="iverview-center-title">
                            お届け先
                        </div>
                        {detail("Name","お名前",props.data.name)}
                        {props.data.type==Constants.deliveryType ?
                        detail("Phone number","電話番号",props.data.phonenumber) : ""}                 
                        {detail("Email","",props.data.email)}
                        {props.data.type==Constants.deliveryType ?
                        detail("Postal code","郵便番号",props.data.postalcode) : ""}   
                        {props.data.type==Constants.deliveryType ?
                        detail("Shipping address","配送先住所",props.data.address) : ""}   
                    </div>          
                </div>
            </>
        )
    }    
    
    function takeDetailsHtml(){
        return(
            <>    
                <div className="overview-block no-border">                                        
                    <div id="details">
                        <div className="iverview-center-title">
                            {props.data.type == Constants.deliveryType ? "お届け先" : ""}
                        </div>
                        {detail("Name","お取り置き名",props.data.name)}               
                        {detail("Email","",props.data.email)}
                        {props.data.type==Constants.takeatliveType ?
                        detail("Pickup Live Name","受け取る会演", props.data.live) : ""} 
                    </div>  
                    <div id="overview-goods">              
                        {props.goods()}   
                    </div>           
                </div>
            </>
        )
    }

    function type1Buttonjapanesetext(){
        return(
            <div className="small-text">お取り置き内容を確定する</div>
        )
    }
    function type2Buttonjapanesetext(){
        return(
            <div className="small-text">ご注文内容を確定する</div>
        )
    }

    const finish =()=>{
        props.overviewComplete(true);
    }

    return(
        <>
        {props.data.type == Constants.deliveryType ? deliveryDetailsHtml() :takeDetailsHtml()}
        <div id="buttons">
            <div className="button" onClick={()=>props.overviewComplete(false)}>
                <div>
                    <div className="small-text">戻る</div>
                    <div>BACK</div>
                </div>
            </div> 
            <div className="button" onClick={finish}>
                <div>
                    {props.type===props.takeatliveType ? type1Buttonjapanesetext() : type2Buttonjapanesetext()}
                    <div>CONFIRM</div>
                </div>
            </div> 
        </div> 
        </>
    )
}