import { useEffect, useState } from "react"
import "../styles/reservation.scss"
import { useNavigate } from "react-router-dom";
import * as Constants from "../config";
import { getTireDate } from "../tools/datehelper";
import uuidv4 from "../tools/guidgenerator";
import Loading from "./loading-screen";

export default function TicketReservation(props){

    const data = {
        id:uuidv4(),
        name:"",
        email:"",
        count:"",
        message:"",
        notifications:true
    };

    const validationErrorData = {
        name:false,
        email:false,
        count:false,
    }

    const navigate = useNavigate();

    const [reservationData, setReservationData] = useState(data);
    const [reservationComplete, setReservationComplete] = useState(false);
    const [validationError, setValidationError] = useState(validationErrorData);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChangeValue = (e) => {        
        const { name, value } = e.target;
        setReservationData({...reservationData, [name]: value});        
    };
    const onChangeCheckbox = (e) => {        
        const { name, checked } = e.target;
        setReservationData({...reservationData, [name]: checked});
    };

    const completeReservation = () =>{        
        saveData(reservationData);
    }

    const goHomePage = () =>{
        navigate("/");
    }

    const bookAnother = () =>{
        setReservationComplete(false);
        props.bookAnother();
    }

    const getFormatedDate = () =>{
        const d = props.date;
        return d.getFullYear() 
        + "." + (d.getMonth() + 1) 
        + "." + d.getDate() 
        + " ("+d.toLocaleString('en', { weekday: 'short' }) + ")"
    }

    const validate = () => {
        let haserror = false;
        let validationErrorNew = {};
        if(!reservationData.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"))
        {
            haserror = true;
            validationErrorNew.email = true;
        }
        else
            validationErrorNew.email = false;
        if(reservationData.name.length < 2 || reservationData.name.length > 30)
        {
            haserror = true;
            validationErrorNew.name = true;
        }
        else
            validationErrorNew.name = false;
        if(reservationData.count <= 0 )
        {
            haserror = true;
            validationErrorNew.count = true;
        }
        else            
            validationErrorNew.count = false;
        setValidationError(validationErrorNew);
        setHasValidationError(haserror);
        return haserror;
    }

    const saveData = (data) => {
        if(validate()) return;
        setLoading(true);
        let savedataObj = {};
        Object.assign(savedataObj,data);
        savedataObj.livename =props.livename;
        savedataObj.time = props.time;
        savedataObj.place = props.place;
        savedataObj.date = getTireDate(props.date);
        const body = Constants.requestBody(savedataObj);
        fetch(Constants.apiurl + Constants.createReservationPath, body)
        .then(response => {
            setReservationComplete(true);
            setTimeout(() => {                
                setLoading(false);
            }, 500);
        }
        )
        .catch(err => {
            setTimeout(() => {                
                setLoading(false);
            }, 500);
            alert("Something went wrong. Try again later.")
            console.log(err.error);
        });
    }

    useEffect(() => {
        if(hasValidationError) validate();
     }, [reservationData]);

    const goBackButtons=()=>{
        return(            
            <div className="confirm-border">
                <img src="home_button.png" className="confirmation-page-logo" />
                <div className="confirmation-header">
                    チケットの予約が完了しました
                    <div>
                        Tickets reservation complete.
                    </div>
                </div>
                <div className="text-center confirmation-text">
                    <div className="japanese-confirmation-text">
                        ご予約ありがとうございます。
                        <br/>
                        公演当日、会場受付スタッフにご入力いただきまし た予約名をお伝えください。
                        <br/>
                        会場でお会いできることを楽しみにしております。
                        <br/>
                        <br/>
                        ※ご入力いただきましたメールアドレスにご予約完 了メールをお送りいたしましたのでご確認ください。
                    </div>
                    <div >
                    Thank you for reservation.
                    <br/>
                    When you come to the show please tell the reception staff the name of reservation you entered.
                    <br/>
                    We are looking forward to see you at the venue.
                    <br/>
                    <br/>
                    *We have sent an email to the entered address to confirm your reservation.
                    </div>
                </div>
                <div className="go-back-buttons">
                    <button onClick={bookAnother} className="button go-back-buttons-text">BOOK ANOTHER</button>
                    <button onClick={goHomePage} className="button go-back-buttons-text">GO HOME PAGE</button>
                </div>
            </div>
        )
    }

    const ticketreservationHeader = () =>{
        return(
                <div className="header-text">
                    TICKET RESERVATION
                </div>  
        )
    }

    const makeReservationHTML =()=>{
        return(
            <>
                <div className="black-text place-text">{getFormatedDate()}</div>
                <div className="black-text live-text">{props.livename}</div>
                <div className="black-text place-text">{props.place}</div>
                <div className="black-text price-text">{props.price}</div>
                    <div className="reservation-inputs">
                        <label >Name ご予約名</label>
                        <label style={{display: validationError.name ? "block" : "none"}} className="red-text">Name should contain from 2 to 30 characters</label>
                        <input autoComplete="off" onChange={onChangeValue} name="name" className="reservation-input" required maxLength="30" placeholder="例）ブイカス" value={reservationData.name} />
                        <label >Email</label>
                        <label style={{display: validationError.email ? "block" : "none"}} className="red-text">Please, input correct email</label>
                        <input autoComplete="off" onChange={onChangeValue}  name="email" className="reservation-input"  required maxLength="50" placeholder="例) example@mail.com" type="email" value={reservationData.email} />
                        <label >Number of tickets ご予約枚数</label>
                        <label style={{display: validationError.count ? "block" : "none"}} className="red-text">Please, select count of tickets</label>
                        <select onChange={onChangeValue}  name="count" className="reservation-input"  required placeholder="Tickets count" value={reservationData.count} >
                            <option style={{display:"none"}} value="" disabled>選択してください</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                        </select>
                        <label >Message ご質問 • ご不明な点など</label>
                        <textarea onChange={onChangeValue} name="message" className="reservation-input reservation-textarea"  placeholder="Message(optional)" maxLength="500" value={reservationData.message} />
                        <div className="checkbox">
                            <input  name="notifications" onChange={onChangeCheckbox} checked={reservationData.notifications} type="checkbox"/>
                            <label>Get notifications about new events by email</label>
                        </div>
                        <button onClick={completeReservation} className="button">COMPLETE RESERVATION</button>
                    </div>
            </>
        )
    }

    return(
        <div>
            <div style={{display:loading ? "" : "none"}}><Loading/></div>
            <div className="resize-block">
                {reservationComplete ? "" : ticketreservationHeader()}          
                <div className="body-block">
                    {reservationComplete ? goBackButtons() : makeReservationHTML()}
                </div>
            </div>
        </div>
    )
}