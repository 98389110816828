import { Button, TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import Jodit from "./jodit";
import uuidv4 from "../tools/guidgenerator";
import { Dataset } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export default function MerchBooking(){
    
    const detdata = [{
        id:uuidv4(),
        name:"",
        email:"",
        liveid:"",
        live:"",
        phonenumber:"",
        postalcode:"",
        address:"",
        message:"",
        paymenttype:"",
        type:"",
    }];

    const [dataState, setDataState] = useState(detdata);
    const [searchdataState, setSearchDataState] = useState(detdata);
    const [searchReservName, setSearchReservName] = useState("");


    const onReservChangeValue = (e) => {        
        const { name, value } = e.target;
        setSearchReservName(value);
    };

    const deleteData = (id) =>{
        fetch(Constants.apiurl + Constants.archiveorder + "?id=" +id, Constants.requestHeaderOnly())
            .then((response) => getData())
            .catch((err) => {
                console.log(err.message);
        });
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.getMerchOrders)
        .then((response) => response.json())
        .then((data) => {
            setDataState(data);
            setSearchDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {        
        getData();
     }, []);

    useEffect(() => {        
        if(searchReservName.length > 0){
            let searchdata = dataState;
            searchdata = searchdata.filter(x=>x.name.toLowerCase().startsWith(searchReservName.toLowerCase()));
            setSearchDataState(searchdata);
        }
        else
            setSearchDataState(dataState);
     }, [searchReservName]);


    const dataHtml = searchdataState.map(item=> 
        <tr>
            <td>{item.name}</td>
            <td>{item.type}</td>
            <td>
                <div>
                    {item.email}
                </div>
                <div>
                    {item.type == Constants.takeatliveType ? item.live : ""}
                </div>
                <div>
                    {item.type == Constants.deliveryType ? item.phonenumber : ""}
                </div>
                <div>
                    {item.type == Constants.deliveryType ? item.postalcode : ""}
                </div>
                <div>
                    {item.type == Constants.deliveryType ? item.address : ""}
                </div>
                <div>
                    {item.message}
                </div>
                <div>
                    {"¥" + item.price}
                </div>
            </td>
            <td>{!!(item.merch) ?
                item.merch.map(item=>
                <div>
                    {item.name + " X " + item.count}
                </div>
            ):""}</td>
            <td>
                 <Button onClick={()=>deleteData(item.id)} variant="outlined" color="error">arvhive</Button> 
            </td>
        </tr>

        )



    return(
        <>
            <div className="reservation-block">
                <table className="reservations-table">
                    <thead>
                        <th id="name">
                            Name
                            <input onChange={onReservChangeValue} value={searchReservName}/>
                        </th>
                        <th id="type">
                            Type
                        </th>
                        <th id="info">Info</th>
                        <th id="order">Order</th>
                    </thead>
                    <tbody>
                        {dataHtml}
                    </tbody>
                </table>
            </div>
        </>
    )
}