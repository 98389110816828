import { useEffect, useState } from "react"
import uuidv4 from "../tools/guidgenerator";
import * as Constants from "../config";
import { getDateFormat } from "../tools/datehelper";

export default function OrderControls(props){


    const validationErrorData = {
        name:false,
        email:false,
        phonenumber:false,
        postalcode:false,
        address:false,
        agreement: false,
        live: false,
    }
    const detdata = {
        id:uuidv4(),
        name:"",
        email:"",
        liveid:"",
        live:"",
        phonenumber:"",
        postalcode:"",
        address:"",
        message:"",
        paymenttype:"",
        type:"",
    };

    var postal_code = require('japan-postal-code');
    const [detailsData,setDetailsData] = useState(detdata);
    const [livesList,setlivesList] = useState([]);
    const [liveid,setliveid] = useState("");

    const [sendComplete, setSendComplete] = useState(false);
    const [validationError, setValidationError] = useState(validationErrorData);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [agreementAccepted, setAgreementAccepted] = useState(false);

    function getLiveInfo(){
        if(liveid=="" || livesList.length <= 0)return;
        const live = livesList.find(x=>x.id==liveid);
        const d = getDateFormat(live.date);
        const date = d.getFullYear() 
        + "." + (d.getMonth() + 1) 
        + "." + d.getDate() 
        + " ("+d.toLocaleString('en', { weekday: 'short' }) + ")";
        var newDetails = {};
        Object.assign(newDetails,detailsData);
        newDetails.live = date + " " + live.livename;
        newDetails.livename = live.livename + " " + live.place;
        newDetails.livedate = date + " " + live.time;
        newDetails.liveid = liveid;
        setDetailsData(newDetails);   
    }

    const validate = () => {
        let haserror = false;
        let validationErrorNew = {};
        if(!detailsData.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"))
        {
            haserror = true;
            validationErrorNew.email = true;
        }
        else
            validationErrorNew.email = false;
        if(detailsData.name.length < 2 || detailsData.name.length > 30)
        {
            haserror = true;
            validationErrorNew.name = true;
        }
        else
            validationErrorNew.name = false;
        if(props.type==Constants.deliveryType)
        {
            if(detailsData.address.length < 5)
            {
                validationErrorNew.address = true;
                haserror = true;
            }
            else
                validationErrorNew.address = false;
            if(detailsData.postalcode.length < 8)
            {
                validationErrorNew.postalcode = true;
                haserror = true;
            }
            else
                validationErrorNew.postalcode = false;
            if(detailsData.phonenumber.length < 11)
            {
                validationErrorNew.phonenumber = true;
                haserror = true;
            }
            else
                validationErrorNew.phonenumber = false;
        }
        if(props.type == Constants.takeatliveType){
            if(agreementAccepted == false)
                haserror = true;
            if(detailsData.liveid == "")
            {
                validationErrorNew.live = true;
                haserror = true;
            }
            else{
                validationErrorNew.live = false;
            }
        }
        setValidationError(validationErrorNew);
        setHasValidationError(haserror);
        return haserror;
    }

    async function getData() {
        fetch(Constants.apiurl + Constants.getlivesPath)
        .then((response) => response.json())
        .then((data) => {
            setlivesList(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    };

    const onChangeValue = (e) => {        
        const { name, value } = e.target;
        var newDetails = {};
        Object.assign(newDetails, detailsData);
        newDetails = {...newDetails, [name]: value}; 
        setDetailsData(newDetails);      
        if(name == "postalcode" && value.length == 8){
            postal_code.get(value.replace("-",""), function(address) { 
                var fulladdress = address.prefecture + " "
                                + address.city  + " "             
                                + address.area  + " "             
                                + address.street;             
                setDetailsData({...newDetails, ["address"]: fulladdress});  
              });
        } 
    };


    const liveIdChanged = (e) => {        
        const { name, value } = e.target;
        setliveid(value);
    };

    const acceptAgreement = () => {        
        setAgreementAccepted(!agreementAccepted);        
    };

    function type1Buttonjapanesetext(){
        return(
            <div className="small-text">お取り置きに進む</div>
        )
    }
    function type2Buttonjapanesetext(){
        return(
            <div className="small-text">レジに進む</div>
        )
    }

    function finish(){
        if(!validate())
            props.detailsComplete(detailsData);
    }

    useEffect(() => {
        getData();
        if(!!(props.data))
            setDetailsData(props.data);
     }, []);

    useEffect(() => {
        if(hasValidationError) validate();
     }, [detailsData]);

    useEffect(() => {
        getLiveInfo();
     }, [liveid]);

     const selectOptions = livesList.map(item=>
            <option value={item.id}>{item.livename + " " + item.date}</option>
        );

     const selectLive = () => {
        return(
            <>
                <label >Choose the live 受取会演選択</label>
                <label style={{display: validationError.live ? "block" : "none"}} className="red-text">Please, select the live</label>
                <select onChange={liveIdChanged}  name="liveid" className="reservation-input"  required placeholder="Tickets count" value={liveid} >
                    <option selected="selected" value="" disabled>選択してください</option>
                    {selectOptions}
                </select>
            </>
        )
     };

     const notes = () =>{
        return(
            <>
                <div className="details-note">
                    <div id="header-title">NOTES 注意事項</div>
                    お取り置きの有効期限はご選択いただいた公演日の開演から終演までとさ せていただきます。 終演を過ぎますとお取り置きがキャンセルとなります のでご注意ください。
                    <br/>
                    会場でのお支払いは現金のみの取り扱いとなります。
                    <br/><br/>
                    The layaway is valid until the end of the show on the selected performance date. Please note that your reservation will be cancelled after the end of the show.
                    <br/>
                    Payment at the venue will be accepted in cash only.
                    <label id="checkbox">
                        <input name="agreement" checked={agreementAccepted} onChange={acceptAgreement} type="checkbox"/>
                        <div id="chekboxtext">
                            <i>
                            注意事項に同意する 
                            <br/>
                            I Agree
                            </i>
                        </div>
                    </label>
                </div>
            </>
        )
     }

     const phonenumber = () => {
        return(
            <>
                <label >Phone number 電話番号</label>
                <label style={{display: validationError.phonenumber ? "block" : "none"}} className="red-text">Please, input correct phone number</label>
                <input autoComplete="off" onChange={onChangeValue}  name="phonenumber" className="reservation-input"  required maxLength="50" placeholder="例) 09036936936" type="phonenumber" value={detailsData.phonenumber} />          
            </>
        )
     }

     const postalcode = () => {
        return(
            <>
                <label >Postal code 郵便番号</label>
                <label style={{display: validationError.postalcode ? "block" : "none"}} className="red-text">Please, input correct postal code</label>
                <input autoComplete="off" onChange={onChangeValue}  name="postalcode" className="reservation-input"  required maxLength="8" placeholder="例) 369-3693" type="postalcode" value={detailsData.postalcode} />          
            </>
        )
     }

     const address = () => {
        return(
            <>
                <label >Shipping address 配送先住所</label>
                <label style={{display: validationError.address ? "block" : "none"}} className="red-text">Please, input correct address</label>
                <input autoComplete="off" onChange={onChangeValue}  name="address" className="reservation-input"  required maxLength="100" placeholder="" type="address" value={detailsData.address} />          
            </>
        )
     }

     const inputsHtml =()=>{
        return(
            <>
                <div className="cart-details-inputs">
                    <div id="details-title">{props.type == Constants.deliveryType ? "お届け先":""}</div>
                    <label >Name 氏名</label>
                    <label style={{display: validationError.name ? "block" : "none"}} className="red-text">Name should contain from 2 to 30 characters</label>
                    <input autoComplete="off" onChange={onChangeValue} name="name" className="reservation-input" required maxLength="30" placeholder="例）武威カス郎" value={detailsData.name} />
                    {props.type == Constants.deliveryType ? phonenumber():""}
                    <label >Email</label>
                    <label style={{display: validationError.email ? "block" : "none"}} className="red-text">Please, input correct email</label>
                    <input autoComplete="off" onChange={onChangeValue}  name="email" className="reservation-input"  required maxLength="50" placeholder="例) example@mail.com" type="email" value={detailsData.email} />
                    {props.type == Constants.takeatliveType ? selectLive():""}
                    {props.type == Constants.deliveryType ? postalcode():""}
                    {props.type == Constants.deliveryType ? address():""}
                    <label >Message ご質問 • ご不明な点など</label>
                    <textarea onChange={onChangeValue} name="message" className="reservation-input reservation-textarea"  placeholder="Message(optional)" maxLength="700" value={detailsData.message} />
                    {props.type == Constants.takeatliveType ? notes():""}
                </div>    
                
                <div id="buttons">
                    <div className="button" onClick={()=>props.saveDetailsData(detailsData)}>
                        <div>
                            <div className="small-text">戻る</div>
                            <div>BACK</div>
                        </div>
                    </div> 
                    <div className="button" onClick={finish}>
                        <div>
                            {props.type===Constants.takeatliveType ? type1Buttonjapanesetext() : type2Buttonjapanesetext()}
                            <div>NEXT</div>
                        </div>
                    </div> 
                </div>            
            </>
        )
    }

    return(
        <>
        {inputsHtml()}
        </>
    )
}