import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

export default function Jodit(props){
	const editor = useRef(null);

	const config = 
		{
			readonly: false, // all options from https://xdsoft.net/jodit/docs/,
			placeholder: "placeholder" || 'Start typings...',
            with:"500px",
            height:"700px",
		}

	return (
        <>
		<JoditEditor
			ref={editor}
			value={props.content}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => props.setTextContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={newContent => {}}
		/>
        <button className="button white-button" onClick={props.textComplete}>COMPLETE</button>
        </>
	);
};