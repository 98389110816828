import Example from "../admin components/liveconfig";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../tools/window_resolution";
import AccordionBlock from "../admin components/accordion_block";
import "../styles/admin.scss";
import GeneralInfo from "../admin components/general_info";
import * as Constants from "../config";
import Login from "./login";
import LiveConfig from "../admin components/liveconfig";
import NewsConfig from "../admin components/newsconfig";
import ImageUploader from "../admin components/imageuploader";
import Reservations from "../admin components/reservations";
import Messages from "../admin components/messages";
import MerchConfig from "../admin components/merchconfig";
import MerchBooking from "../admin components/merchbooking";
import MembersConfig from "../admin components/members";
import VideoConfig from "../admin components/videoconfig";

export default function Admin(){

    const screenheight = useWindowDimensions().height;
    const screenwidth = useWindowDimensions().width;
    const [auth,setAuth] = useState(true);
    const [uploaderActiveState,setUploaderActiveState] = useState(false);
    

    const layoutStyle = {
        paddingTop:"100px",
        display:"flex",
        justifyContent:"center",
    };

    const contentStyle = {
        width: screenwidth > 1000 ? "1000px" : screenwidth - 20,
        paddingBottom:"300px",
    };

    const pageStyle = {
        position:"fixed",
        zIndex: "-2",
        height: "100%",
        width:"100%",
        backgroundColor:"black"
    };

    const livesContent = (
        <LiveConfig/>);

    const newsContent = (
        <NewsConfig/>);

    const reservationsHtml = (
        <Reservations/>);

    const messagesHtml = (
        <Messages/>);

    const merchHtml = (
        <MerchConfig/>);
        
    const mercBookinghHtml = (
        <MerchBooking/>);

    useEffect(() => {
        checkLogin();
        }, []);

    const checkLogin = () => {
        const token = localStorage.getItem("token");
        fetch(Constants.apiurl + Constants.checkAuthPath, { headers: {"Authorization" : token} })
        .then((response) => {
            if( response.status != 401)
                setAuth(true);
            else
                setAuth(false);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const loginCompleted =()=>{
        setAuth(true);
    };
    
    return(
        <>

            <div style={pageStyle}>            
            </div>            
            <div className="admin-page" style={layoutStyle}>
                <div style={contentStyle}>           
                    <div style={{display:!auth ?"block":"none"}}> <Login loginCompleted={loginCompleted}/></div>            
                    <div style={{display:auth ?"":"none"}}>
                    <ImageUploader active={uploaderActiveState} handleChoose={()=>setUploaderActiveState(false)}/>
                    <button onClick={()=>setUploaderActiveState(true)} className="white-button button">Upload Images</button>
                    {/* <AccordionBlock expanded={true} name={"総合"} content={generalInfo}  /> */}
                    <AccordionBlock name={"Members"} content={<MembersConfig/>} />                              
                    <AccordionBlock name={"Videos"} content={<VideoConfig/>}  />                                   
                    <AccordionBlock name={"Lives"} content={livesContent}  />                                     
                    <AccordionBlock name={"News"} content={newsContent}  />                                  
                    <AccordionBlock name={"Merch"} content={merchHtml}  />                                  
                    <AccordionBlock name={"Reservations"} content={reservationsHtml}  />                                  
                    <AccordionBlock name={"Merch Orders"} content={mercBookinghHtml}  />                                  
                    <AccordionBlock name={"Messages"} content={messagesHtml}  />                                  
                    </div>
                </div>
            </div>
        </>
    )
}