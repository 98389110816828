import { Button, TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import Jodit from "./jodit";
import uuidv4 from "../tools/guidgenerator";
import { Dataset } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export default function Messages(){
    
    const data = [{
        id:0,
        date: "",
        name: "",
        email: "",
        message: "",
    }];

    const [dataState, setDataState] = useState(data);
    const [searchdataState, setSearchDataState] = useState(data);
    const [searchLiveName, setSearchLiveName] = useState("");
    const [searchReservName, setSearchReservName] = useState("");

    const onLiveChangeValue = (e) => {        
        const { name, value } = e.target;
        setSearchLiveName(value);        
    };
    
    const deleteData = (id) =>{
        fetch(Constants.apiurl + Constants.archivemessage + "?id=" +id, Constants.requestHeaderOnly())
            .then((response) => getData())
            .catch((err) => {
                console.log(err.message);
        });
    };

    const onReservChangeValue = (e) => {        
        const { name, value } = e.target;
        setSearchReservName(value);
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.contactUsPath)
        .then((response) => response.json())
        .then((data) => {
            data.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
               });
            setDataState(data);
            setSearchDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {        
        getData();
     }, []);

    useEffect(() => {        
        console.log(123);
        if(searchLiveName.length > 0 || searchReservName.length > 0){
            let searchdata = dataState;
            if(searchLiveName.length > 0)
                searchdata = searchdata.filter(x=>x.livename.toLowerCase().startsWith(searchLiveName.toLowerCase()));
            if(searchReservName.length > 0)
                searchdata = searchdata.filter(x=>x.name.toLowerCase().startsWith(searchReservName.toLowerCase()));
            setSearchDataState(searchdata);
        }
        else
            setSearchDataState(dataState);
     }, [searchLiveName, searchReservName]);


    const dataHtml = searchdataState.map(item=> 
        <tr>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.message}</td>
            <td>{item.date}</td>
            <td>
                 <Button onClick={()=>deleteData(item.id)} variant="outlined" color="error">arvhive</Button> 
            </td>
        </tr>

        )



    return(
        <>
            <div className="reservation-block">
                <table className="reservations-table">
                    <thead>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>Message</th>
                        <th>Date</th>
                    </thead>
                    <tbody>
                        {dataHtml}
                    </tbody>
                </table>
            </div>
        </>
    )
}